import * as React from 'react';
import { Box } from '@redskytech/framework/ui';
import { TestKey } from '../FlightTestSection';
import { useRecoilValue } from 'recoil';
import { TestResult } from '../../../../services/testFixture/ITestFixtureService';
import globalState from '../../../../state/globalState';
import { useEffect, useState } from 'react';
import EvaluationItem, {
	EvaluationGroup,
	EvaluationOptions
} from '../../../../components/evaluationItem/EvaluationItem';
import ServiceFactory from '../../../../services/serviceFactory';

interface PayloadVerifyEsadDetonationProps {}

type PayloadVerifyEsadDetonationEvaluationGroup = EvaluationGroup & {
	armEsad1ReadyToArmed: EvaluationOptions | undefined;
	disarmEsad1ArmedToDetonate: EvaluationOptions | undefined;
	armEsad2ReadyToArmed: EvaluationOptions | undefined;
	disarmEsad2ArmedToDetonate: EvaluationOptions | undefined;
	armEsad3ReadyToArmed: EvaluationOptions | undefined;
	disarmEsad3ArmedToDetonate: EvaluationOptions | undefined;
};

type EvaluationKey = keyof PayloadVerifyEsadDetonationEvaluationGroup;

const PayloadVerifyEsadDetonation: React.FC<PayloadVerifyEsadDetonationProps> = () => {
	const TEST_NAME: TestKey = 'verifyEsadDetonation';
	const testResults = useRecoilValue<TestResult[]>(globalState.testResults);
	let res = testResults.find((prevRes) => prevRes.testName === TEST_NAME);
	const testFixtureService = ServiceFactory.get('TestFixtureService');
	const [evaluations, setEvaluations] = useState<PayloadVerifyEsadDetonationEvaluationGroup>({
		armEsad1ReadyToArmed: res ? res.data['armEsad1ReadyToArmed'] : undefined,
		disarmEsad1ArmedToDetonate: res ? res.data['disarmEsad1ArmedToDetonate'] : undefined,
		armEsad2ReadyToArmed: res ? res.data['armEsad2ReadyToArmed'] : undefined,
		disarmEsad2ArmedToDetonate: res ? res.data['disarmEsad2ArmedToDetonate'] : undefined,
		armEsad3ReadyToArmed: res ? res.data['armEsad3ReadyToArmed'] : undefined,
		disarmEsad3ArmedToDetonate: res ? res.data['disarmEsad3ArmedToDetonate'] : undefined
	});

	useEffect(() => {
		testFixtureService.validateEvaluationTest(TEST_NAME, evaluations);
	}, [evaluations]);

	function handleEvaluationClick(evaluation: EvaluationOptions, key: EvaluationKey) {
		setEvaluations((prevState) => {
			let updatedState = { ...prevState };
			updatedState[key] = evaluation;
			return updatedState;
		});
	}
	return (
		<Box className={'rsVerifyEsadReady'} display={'flex'} flexDirection={'column'} gap={16}>
			<EvaluationItem
				label={'Arm #1: ESAD transition from “Ready” to “Armed”'}
				onEvaluationClick={(evaluation) => {
					handleEvaluationClick(evaluation, 'armEsad1ReadyToArmed');
				}}
				evaluation={evaluations.armEsad1ReadyToArmed}
			/>
			<EvaluationItem
				label={'Disarm #1: ESAD transition from “Armed” to “Detonate”'}
				onEvaluationClick={(evaluation) => {
					handleEvaluationClick(evaluation, 'disarmEsad1ArmedToDetonate');
				}}
				evaluation={evaluations.disarmEsad1ArmedToDetonate}
			/>
			<EvaluationItem
				label={'Arm #2: ESAD transition from “Ready” to “Armed”'}
				onEvaluationClick={(evaluation) => {
					handleEvaluationClick(evaluation, 'armEsad2ReadyToArmed');
				}}
				evaluation={evaluations.armEsad2ReadyToArmed}
			/>
			<EvaluationItem
				label={'Disarm #2: ESAD transition from “Armed” to “Detonate”'}
				onEvaluationClick={(evaluation) => {
					handleEvaluationClick(evaluation, 'disarmEsad2ArmedToDetonate');
				}}
				evaluation={evaluations.disarmEsad2ArmedToDetonate}
			/>{' '}
			<EvaluationItem
				label={'Arm #3: ESAD transition from “Ready” to “Armed”'}
				onEvaluationClick={(evaluation) => {
					handleEvaluationClick(evaluation, 'armEsad3ReadyToArmed');
				}}
				evaluation={evaluations.armEsad3ReadyToArmed}
			/>
			<EvaluationItem
				label={'Disarm #3: ESAD transition from “Armed” to “Detonate”'}
				onEvaluationClick={(evaluation) => {
					handleEvaluationClick(evaluation, 'disarmEsad3ArmedToDetonate');
				}}
				evaluation={evaluations.disarmEsad3ArmedToDetonate}
			/>
		</Box>
	);
};
export default PayloadVerifyEsadDetonation;
