import * as React from 'react';
import {
	Box,
	Checkbox,
	Label,
	LabelInputText,
	popupController,
	RsFormControl,
	RsFormGroup,
	rsToastify,
	RsValidator,
	RsValidatorEnum
} from '@redskytech/framework/ui';
import { TestKey } from '../FlightTestSection';
import ServiceFactory from '../../../../services/serviceFactory';
import { useRecoilValue } from 'recoil';
import { TestCriteria, TestResult } from '../../../../services/testFixture/ITestFixtureService';
import globalState from '../../../../state/globalState';
import { useEffect, useState, useMemo } from 'react';
import { IRsFormControl } from '@redskytech/framework/ui/form/FormControl';

import WarningPopup, { WarningPopupProps } from '../../../../popups/warningPopup/WarningPopup';
import EvaluationItem, {
	EvaluationGroup,
	EvaluationOptions
} from '../../../../components/evaluationItem/EvaluationItem';

interface RadioRangeTestingProps {}

type ReviewRadioEvaluationGroup = EvaluationGroup & {
	acceptRadioRangeQualityManually: EvaluationOptions | undefined;
};

type EvaluationKey = keyof ReviewRadioEvaluationGroup;

enum FormKeys {
	RSSI_NOSE_IN_VEH_1 = 'rssiVehNoseIn1',
	RSSI_NOSE_IN_VEH_2 = 'rssiVehNoseIn2',
	RSSI_NOSE_IN_VEH_DELTA = 'rssiAbsVehNoseInDelta',
	RSSI_NOSE_IN_FCU_1 = 'rssiFcuNoseIn1',
	RSSI_NOSE_IN_FCU_2 = 'rssiFcuNoseIn2',
	RSSI_NOSE_IN_FCU_DELTA = 'rssiAbsFcuNoseInDelta',

	THROUGHPUT_NOSE_IN = 'throughputFromFcuNoseIn',
	NOSE_IN_VIDEO_QUALITY = 'noseInVideoQuality',

	ACCEPT_RADIO_RANGE_QUALITY = 'acceptRadioRangeQualityManually'
}

const rssiTestCriteria: TestCriteria[] = [
	{
		fieldName: FormKeys.RSSI_NOSE_IN_VEH_1,
		value: -65,
		operator: 'gte'
	},

	{
		fieldName: FormKeys.RSSI_NOSE_IN_VEH_2,
		value: -65,
		operator: 'gte'
	},
	{
		fieldName: FormKeys.RSSI_NOSE_IN_FCU_1,
		value: -65,
		operator: 'gte'
	},
	{
		fieldName: FormKeys.RSSI_NOSE_IN_FCU_2,
		value: -65,
		operator: 'gte'
	},
	{
		fieldName: FormKeys.RSSI_NOSE_IN_VEH_DELTA,
		value: 3,
		operator: 'lte'
	},
	{
		fieldName: FormKeys.NOSE_IN_VIDEO_QUALITY,
		value: true,
		operator: 'eq'
	}
];

const RadioRangeTesting: React.FC<RadioRangeTestingProps> = (props) => {
	const TEST_NAME: TestKey = 'radioRangeTesting';
	const testFixtureService = ServiceFactory.get('TestFixtureService');
	const user = useRecoilValue<Api.V1.User.Me.Get.Res | undefined>(globalState.user);
	const testResults = useRecoilValue<TestResult[]>(globalState.testResults);
	let res = useMemo(() => testResults.find((prevRes) => prevRes.testName === TEST_NAME), [testResults]);
	const [popupState, setPopupState] = useState({ isVisible: false, message: '', title: '' });
	const [evaluations, setEvaluations] = useState<ReviewRadioEvaluationGroup>({
		acceptRadioRangeQualityManually: res ? res.data['acceptRadioRangeQualityManually'] : undefined
	});
	const [formGroup, setFormGroup] = useState<RsFormGroup>(
		new RsFormGroup([
			new RsFormControl<string>(FormKeys.RSSI_NOSE_IN_FCU_1, res ? res.data[FormKeys.RSSI_NOSE_IN_FCU_1] : '', [
				new RsValidator(RsValidatorEnum.CUSTOM, '', (control) => {
					const limit = rssiTestCriteria.find(
						(criteria) => criteria.fieldName === FormKeys.RSSI_NOSE_IN_FCU_1
					);
					return testFixtureService.validateResultInRange(
						control,
						0,
						limit?.value ? Math.abs(Number(limit.value)) : 0,
						true
					);
				})
			]),
			new RsFormControl<string>(FormKeys.RSSI_NOSE_IN_FCU_2, res ? res.data[FormKeys.RSSI_NOSE_IN_FCU_2] : '', [
				new RsValidator(RsValidatorEnum.CUSTOM, '', (control) => {
					const limit = rssiTestCriteria.find(
						(criteria) => criteria.fieldName === FormKeys.RSSI_NOSE_IN_FCU_2
					);
					return testFixtureService.validateResultInRange(
						control,
						0,
						limit?.value ? Math.abs(Number(limit.value)) : 0,
						true
					);
				})
			]),
			new RsFormControl<string>(FormKeys.RSSI_NOSE_IN_VEH_1, res ? res.data[FormKeys.RSSI_NOSE_IN_VEH_1] : '', [
				new RsValidator(RsValidatorEnum.CUSTOM, '', (control) => {
					const limit = rssiTestCriteria.find(
						(criteria) => criteria.fieldName === FormKeys.RSSI_NOSE_IN_VEH_1
					);
					return testFixtureService.validateResultInRange(
						control,
						0,
						limit?.value ? Math.abs(Number(limit.value)) : 0,
						true
					);
				})
			]),
			new RsFormControl<string>(FormKeys.RSSI_NOSE_IN_VEH_2, res ? res.data[FormKeys.RSSI_NOSE_IN_VEH_2] : '', [
				new RsValidator(RsValidatorEnum.CUSTOM, '', (control) => {
					const limit = rssiTestCriteria.find(
						(criteria) => criteria.fieldName === FormKeys.RSSI_NOSE_IN_VEH_2
					);
					return testFixtureService.validateResultInRange(
						control,
						0,
						limit?.value ? Math.abs(Number(limit.value)) : 0,
						true
					);
				})
			]),
			new RsFormControl<string>(
				FormKeys.RSSI_NOSE_IN_VEH_DELTA,
				res ? res.data[FormKeys.RSSI_NOSE_IN_VEH_DELTA] : '',
				[
					new RsValidator(RsValidatorEnum.CUSTOM, '', (control) => {
						const limit = rssiTestCriteria.find(
							(criteria) => criteria.fieldName === FormKeys.RSSI_NOSE_IN_VEH_DELTA
						);
						return testFixtureService.validateResultInRange(
							control,
							0,
							limit?.value ? Math.abs(Number(limit.value)) : 0,
							true
						);
					})
				]
			),
			new RsFormControl<string>(
				FormKeys.RSSI_NOSE_IN_FCU_DELTA,
				res ? res.data[FormKeys.RSSI_NOSE_IN_FCU_DELTA] : '',
				[
					new RsValidator(RsValidatorEnum.CUSTOM, '', (control) => {
						return control.value !== '' ? true : false;
					})
				]
			),
			new RsFormControl<string>(FormKeys.THROUGHPUT_NOSE_IN, res ? res.data[FormKeys.THROUGHPUT_NOSE_IN] : '', [
				new RsValidator(RsValidatorEnum.CUSTOM, '', (control) => {
					return control.value !== '' ? true : false;
				})
			]),

			new RsFormControl<boolean>(
				FormKeys.NOSE_IN_VIDEO_QUALITY,
				res ? res.data[FormKeys.NOSE_IN_VIDEO_QUALITY] : false,
				[
					new RsValidator(RsValidatorEnum.CUSTOM, '', (control) => {
						return !!control.value;
					})
				]
			),
			new RsFormControl<string>(
				FormKeys.ACCEPT_RADIO_RANGE_QUALITY,
				res ? res.data[FormKeys.ACCEPT_RADIO_RANGE_QUALITY] : '',
				[
					new RsValidator(RsValidatorEnum.CUSTOM, '', (control) => {
						return control.value !== '';
					})
				]
			)
		])
	);

	useEffect(() => {
		if (!popupState.isVisible) return;
		let popupId = popupController.open<WarningPopupProps>(WarningPopup, {
			title: popupState.title,
			message: popupState.message,
			confirmButtonText: 'Continue',
			onConfirm: () => {
				setPopupState((prev) => {
					return { isVisible: false, message: '', title: '' };
				});
			}
		});

		return () => {
			if (popupId) {
				popupController.closeById(popupId);
			}
		};
	}, [popupState]);

	function checkRssiValue(rssiValue: number, rssiThresholdLow: number, rssiThresholdHigh: number) {
		const rssiValueAbs = Math.abs(rssiValue);
		const rssiThresholdLowAbs = 50;

		if (rssiValueAbs < rssiThresholdLowAbs) {
			setPopupState((prev) => {
				return {
					isVisible: true,
					message: `RSSI value is very high, please inspect test setup. Received -${rssiValue}, expected value lower than -${rssiThresholdLowAbs}`,
					title: 'Comm Fault Warning'
				};
			});
		}
	}

	function handleUpdateControl(control: RsFormControl<IRsFormControl>) {
		// Sanitize the control value for the input in storage
		const newControlValue = testFixtureService.controlValueToNumber(control);

		// sanitize inputs to become numbers, and negative values where appropriate
		if (newControlValue !== undefined && !control.key.includes('throughput')) {
			control.value = Math.abs(newControlValue) * -1.0;
		} else if (newControlValue !== undefined) {
			control.value = newControlValue;
		}

		let cloneGroup = formGroup.clone().update(control);

		// calculate averages and deltas since we are storing them now.

		if (control.key === FormKeys.RSSI_NOSE_IN_VEH_1 || control.key === FormKeys.RSSI_NOSE_IN_VEH_2) {
			let control1 = cloneGroup.get(FormKeys.RSSI_NOSE_IN_VEH_1);
			let control2 = cloneGroup.get(FormKeys.RSSI_NOSE_IN_VEH_2);
			let controlDelta = cloneGroup.get(FormKeys.RSSI_NOSE_IN_VEH_DELTA);

			if (control1 !== undefined || control2 !== undefined) {
				let value1 = testFixtureService.controlValueToNumber(control1);
				let value2 = testFixtureService.controlValueToNumber(control2);

				if (value1 !== undefined && value2 !== undefined) {
					let delta = Math.abs(Math.abs(value1) - Math.abs(value2));
					controlDelta.value = Math.abs(delta);
					cloneGroup.update(controlDelta);
				}
			}
		}

		if (control.key === FormKeys.RSSI_NOSE_IN_FCU_1 || control.key === FormKeys.RSSI_NOSE_IN_FCU_2) {
			let control1 = cloneGroup.get(FormKeys.RSSI_NOSE_IN_FCU_1);
			let control2 = cloneGroup.get(FormKeys.RSSI_NOSE_IN_FCU_2);
			let controlDelta = cloneGroup.get(FormKeys.RSSI_NOSE_IN_FCU_DELTA);

			if (control1 !== undefined || control2 !== undefined) {
				let value1 = testFixtureService.controlValueToNumber(control1);
				let value2 = testFixtureService.controlValueToNumber(control2);

				if (value1 !== undefined && value2 !== undefined) {
					let delta = Math.abs(Math.abs(value1) - Math.abs(value2));
					controlDelta.value = Math.abs(delta);
					cloneGroup.update(controlDelta);
				}
			}
		}
		setFormGroup(cloneGroup);
		testFixtureService.validateFlightTest(
			cloneGroup,
			TEST_NAME,
			false,
			FormKeys.ACCEPT_RADIO_RANGE_QUALITY,
			rssiTestCriteria
		);
	}

	function handleEvaluationClick(evaluation: EvaluationOptions | undefined, key: EvaluationKey) {
		if (key === FormKeys.ACCEPT_RADIO_RANGE_QUALITY) {
			let control = formGroup.get(FormKeys.ACCEPT_RADIO_RANGE_QUALITY);
			if (evaluation === undefined) {
				control.resetToInitial();
			} else {
				control.value = evaluation === 'PASS';
			}
			setFormGroup((prev) => prev.clone().update(control));
		}

		setEvaluations((prevState) => {
			let updatedState = { ...prevState };
			updatedState[key] = evaluation;
			return updatedState;
		});

		testFixtureService.validateFlightTest(
			formGroup,
			TEST_NAME,
			false,
			FormKeys.ACCEPT_RADIO_RANGE_QUALITY,
			rssiTestCriteria
		);
	}

	function renderTestItem(
		label: string,
		formKeyVehRssi1: FormKeys,
		formKeyVehRssi2: FormKeys,
		formKeyFcuRssi1: FormKeys | undefined,
		formKeyFcuRssi2: FormKeys | undefined,
		formKeyThroughput: FormKeys,
		formKeyVideoQuality: FormKeys
	) {
		let thresholds = label.includes('Out') || label.includes('In') ? [65, 1000] : [65, 1000];

		return (
			<Box key={label} marginBottom={16}>
				<Label weight={'bold'} variant={'body1'}>{`${label}`}</Label>
				<Box display={'grid'} gridTemplateColumns={'1fr 1fr'} gap={16}>
					<LabelInputText
						labelTitle={'RSSI 1 from Vehicle'}
						inputMode={'text'}
						type={'text'}
						control={formGroup.get(formKeyVehRssi1)}
						updateControl={handleUpdateControl}
						onBlur={(event) => {
							checkRssiValue(parseFloat(event.target.value), thresholds[0], thresholds[1]);
						}}
					/>
					<LabelInputText
						labelTitle={'RSSI 2 from Vehicle'}
						inputMode={'text'}
						type={'text'}
						control={formGroup.get(formKeyVehRssi2)}
						updateControl={handleUpdateControl}
						onBlur={(event) => {
							checkRssiValue(parseFloat(event.target.value), thresholds[0], thresholds[1]);
						}}
					/>
					{formKeyFcuRssi1 && formKeyFcuRssi2 && (
						<>
							<LabelInputText
								labelTitle={'RSSI 1 from FCU'}
								inputMode={'text'}
								type={'text'}
								control={formGroup.get(formKeyFcuRssi1 ? formKeyFcuRssi1 : '')}
								updateControl={handleUpdateControl}
								onBlur={(event) => {
									checkRssiValue(parseFloat(event.target.value), thresholds[0], thresholds[1]);
								}}
							/>
							<LabelInputText
								labelTitle={'RSSI 2 from FCU'}
								inputMode={'text'}
								type={'text'}
								control={formGroup.get(formKeyFcuRssi2 ? formKeyFcuRssi2 : '')}
								updateControl={handleUpdateControl}
								onBlur={(event) => {
									checkRssiValue(parseFloat(event.target.value), thresholds[0], thresholds[1]);
								}}
							/>
						</>
					)}
					<LabelInputText
						labelTitle={'Throughput from FCU'}
						inputMode={'text'}
						type={'text'}
						control={formGroup.get(formKeyThroughput)}
						updateControl={handleUpdateControl}
					/>
				</Box>
				<Box></Box>
				<Box marginTop={8} display={'grid'} gridTemplateColumns={'1fr 1fr'}>
					<Checkbox
						labelText={'No snowy video during rssi test'}
						look={'containedPrimary'}
						control={formGroup.get(formKeyVideoQuality)}
						updateControl={handleUpdateControl}
					/>
				</Box>
			</Box>
		);
	}

	return (
		<Box className={'rsRadioRangeTesting'} key={TEST_NAME}>
			{[
				renderTestItem(
					'Nose In',
					FormKeys.RSSI_NOSE_IN_VEH_1,
					FormKeys.RSSI_NOSE_IN_VEH_2,
					FormKeys.RSSI_NOSE_IN_FCU_1,
					FormKeys.RSSI_NOSE_IN_FCU_2,
					FormKeys.THROUGHPUT_NOSE_IN,
					FormKeys.NOSE_IN_VIDEO_QUALITY
				)
			]}
			{user?.role === 'admin' && (
				<EvaluationItem
					label={'Radio Range Testing manually reviewed and approved'}
					resettable={true}
					onEvaluationClick={(evaluation) => {
						console.log(evaluation);
						handleEvaluationClick(evaluation, FormKeys.ACCEPT_RADIO_RANGE_QUALITY);
					}}
					evaluation={evaluations.acceptRadioRangeQualityManually}
				/>
			)}
		</Box>
	);
};
export default RadioRangeTesting;
