import * as React from 'react';
import { useEffect, useState } from 'react';
import { Box } from '@redskytech/framework/ui';
import { TestKey } from '../FlightTestSection';
import { useRecoilValue } from 'recoil';
import { TestResult } from '../../../../services/testFixture/ITestFixtureService';
import globalState from '../../../../state/globalState';
import EvaluationItem, {
	EvaluationOptions,
	EvaluationGroup
} from '../../../../components/evaluationItem/EvaluationItem';
import ServiceFactory from '../../../../services/serviceFactory';

interface ReviewPlottingResultsProps {}

type ReviewPlottingEvaluationGroup = EvaluationGroup & {
	allPlotResultsWithinThresholds: EvaluationOptions | undefined;
	flightLogDataQualityManuallyAccepted: EvaluationOptions | undefined;
};

type EvaluationKey = keyof ReviewPlottingEvaluationGroup;

const ReviewPlottingResults: React.FC<ReviewPlottingResultsProps> = () => {
	const TEST_NAME: TestKey = 'reviewPlottingResults';
	const user = useRecoilValue<Api.V1.User.Me.Get.Res | undefined>(globalState.user);
	const testResults = useRecoilValue<TestResult[]>(globalState.testResults);
	let res = testResults.find((prevRes) => prevRes.testName === TEST_NAME);
	const testFixtureService = ServiceFactory.get('TestFixtureService');
	const [evaluations, setEvaluations] = useState<ReviewPlottingEvaluationGroup>({
		allPlotResultsWithinThresholds: res ? res.data['allPlotResultsWithinThresholds'] : undefined,
		flightLogDataQualityManuallyAccepted: res ? res.data['flightLogDataQualityManuallyAccepted'] : undefined
	});

	useEffect(() => {
		testFixtureService.validateEvaluationTest(TEST_NAME, evaluations, 'flightLogDataQualityManuallyAccepted');
	}, [evaluations]);

	function handleEvaluationClick(evaluation: EvaluationOptions | undefined, key: EvaluationKey) {
		setEvaluations((prevState) => {
			let updatedState = { ...prevState };
			updatedState[key] = evaluation;
			return updatedState;
		});
	}

	return (
		<Box className={'rsReviewPlottingResults'} display={'flex'} flexDirection={'column'} gap={16}>
			<EvaluationItem
				label={'Automated threshold plot review'}
				onEvaluationClick={(evaluation) => {
					handleEvaluationClick(evaluation, 'allPlotResultsWithinThresholds');
				}}
				evaluation={evaluations.allPlotResultsWithinThresholds}
			/>
			{user?.role === 'admin' && (
				<EvaluationItem
					label={'Flight results manually reviewed and approved'}
					resettable={true}
					onEvaluationClick={(evaluation) => {
						handleEvaluationClick(evaluation, 'flightLogDataQualityManuallyAccepted');
					}}
					evaluation={evaluations.flightLogDataQualityManuallyAccepted}
				/>
			)}
		</Box>
	);
};
export default ReviewPlottingResults;
