import {
	Box,
	Button,
	Label,
	LabelInputText,
	popupController,
	RsFormControl,
	RsFormGroup,
	rsToastify
} from '@redskytech/framework/ui';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { useRecoilState } from 'recoil';
import FlightTestItem from '../../../components/flightTestItem/FlightTestItem';
import PageHeader from '../../../components/pageHeader/PageHeader';
import SelectableInputText from '../../../components/selectableInputText/SelectableInputText';
import StatusChip from '../../../components/statusChip/StatusChip';
import useWarnOnUnsavedChanges from '../../../customHooks/useWarnOnUnsavedChanges';
import { ApiRequestV1 } from '../../../generated/apiRequests';
import LoadingPopup, { LoadingPopupProps } from '../../../popups/loadingPopup/LoadingPopup';
import { HardwareIdDecoded } from '../../../services/assembly/AssemblyService';
import {
	autoCreateableAssemblyPartNumbers,
	fcuPartNumbers,
	livePayloadPartNumbers,
	payloadPartNumbers,
	trainerPayloadPartNumbers,
	vehiclePartNumbers
} from '../../../services/assembly/assembly.data';
import { FlightFileData } from '../../../services/calibration/ICalibrationService';
import serviceFactory from '../../../services/serviceFactory';
import { TestResult } from '../../../services/testFixture/ITestFixtureService';
import globalState from '../../../state/globalState';
import { ObjectUtils, StringUtils, WebUtils } from '../../../utils/utils';
import { TestStatus } from '../../testDetailsPage/TestDetailsPage';
import './FlightTestSection.scss';
import {
	vehicleFlightTestData,
	vehicleFlightTestDataNoVideo,
	vehicleInFlightTestData,
	vehiclePostFlightTestData,
	vehiclePostFlightTestDataNoVideo,
	vehiclePreFlightTestData,
	VehicleTestKey
} from './VehicleFlightTestList';

import { IRsFormControl } from '@redskytech/framework/ui/form/FormControl';
import debounce from 'lodash.debounce';
import WarningPopup, { WarningPopupProps } from '../../../popups/warningPopup/WarningPopup';
import { fcuFlightTestData, fcuInFlightTestData, fcuPreFlightTestData, FcuTestKey } from './FcuFlightTestList';
import {
	livePayloadFlightTestData,
	livePayloadFlightTestDataNoVideo,
	livePayloadInFlightTestData,
	livePayloadPostFlightTestData,
	livePayloadPostFlightTestDataNoVideo,
	livePayloadPreFlightTestData,
	LivePayloadTestKey
} from './LivePayloadFlightTestList';
import {
	trainerPayloadFlightTestData,
	trainerPayloadFlightTestDataNoVideo,
	trainerPayloadInFlightTestData,
	trainerPayloadPostFlightTestData,
	trainerPayloadPostFlightTestDataNoVideo,
	trainerPayloadPreFlightTestData,
	TrainerPayloadTestKey
} from './TrainerPayloadFlightTestList';
import LiveRunPreflight from './flightTests/LiveRunPreflight';
import RunPreflight from './flightTests/RunPreflight';

export interface FlightLogUploadRequest {
	filePath: string;
	url: string;
	mimeType: string;
}

interface MessageUploadSectionProps {
	hwid: HardwareIdDecoded;
	onDone: () => void;
}

export type TestKey = TrainerPayloadTestKey | LivePayloadTestKey | VehicleTestKey | FcuTestKey;

enum FormKeys {
	ASSOCIATED_ASSEMBLY_1 = 'associatedAssembly',
	ASSOCIATED_ASSEMBLY_2 = 'associatedAssembly2',
	ASSOCIATED_BATTERIES = 'associatedBattery'
}

const FlightTestSection: React.FC<MessageUploadSectionProps> = (props) => {
	const assemblyService = serviceFactory.get('AssemblyService');
	const [isModified, setIsModified] = useState<boolean>(false);
	const [selectedTest, setSelectedTest] = useState<TestKey>('cameraInspection');
	const [connectionGuid] = useState<string>(() => StringUtils.generateGuid());
	const [testResults, setTestResults] = useRecoilState<TestResult[]>(globalState.testResults);
	const [fileData, setFileData] = useRecoilState<FlightFileData[] | undefined>(globalState.flightTestFileData);
	const [preFlightTestData, setPreFlightTestData] = useState<any>(vehiclePreFlightTestData);
	const [inFlightTestData, setInFlightTestData] = useState<any>(vehicleInFlightTestData);
	const [postFlightTestData, setPostFlightTestData] = useState<any>(vehiclePostFlightTestData);
	const [flightTestData, setFlightTestData] = useState<any>(vehicleFlightTestData);
	const [showPostFlightSection, setShowPostFlightSection] = useState<boolean>(true);
	const [flightLabel, setFlightLabel] = useState<string>('Vehicle Flight Test');
	const [associatedBatteries, setAssociatedBatteries] = useState<string[]>([]);
	const [associatedAssembliesFormGroup, setAssociatedAssembliesFormGroup] = useState<RsFormGroup>(
		new RsFormGroup([
			new RsFormControl<string>(FormKeys.ASSOCIATED_ASSEMBLY_1, '', []),
			new RsFormControl<string>(FormKeys.ASSOCIATED_ASSEMBLY_2, '', [])
		])
	);

	useWarnOnUnsavedChanges(isModified);

	useEffect(() => {
		// Reset when we first mount
		setTestResults([]);
		setFileData(undefined);

		return () => {
			// clean up when we unmount.
			setTestResults([]);
			setFileData(undefined);
		};
	}, []);

	useEffect(() => {
		const socketIoService = serviceFactory.get('SocketioService');
		if (props.hwid.partNumber && trainerPayloadPartNumbers.includes(props.hwid.partNumber)) {
			setPreFlightTestData(trainerPayloadPreFlightTestData);
			let updatedPayloadInFlightTestData = trainerPayloadInFlightTestData;
			let payloadPartType = assemblyService.getPartAssemblyTypeFromPartNumber(props.hwid.partNumber);
			if (payloadPartType === undefined) payloadPartType = 'TRAINER_PAYLOAD_ASSEMBLY';
			updatedPayloadInFlightTestData.runPreflight = (
				<RunPreflight key={'updatedPreflight'} targetPayloadType={payloadPartType} />
			);

			setInFlightTestData(updatedPayloadInFlightTestData);
			if (socketIoService.getVideoFeatureAvailable()) {
				let updatedPayloadFlightTestData = trainerPayloadFlightTestData;
				console.log(payloadPartType);
				updatedPayloadFlightTestData.runPreflight = (
					<RunPreflight key={'updatedPreflight'} targetPayloadType={payloadPartType} />
				);

				setFlightTestData(updatedPayloadFlightTestData);
				setPostFlightTestData(trainerPayloadPostFlightTestData);
			} else {
				let updatedPayloadFlightTestData = trainerPayloadFlightTestDataNoVideo;
				updatedPayloadFlightTestData.runPreflight = (
					<RunPreflight key={'updatedPreflight'} targetPayloadType={payloadPartType} />
				);

				setFlightTestData(updatedPayloadFlightTestData);
				setPostFlightTestData(trainerPayloadPostFlightTestDataNoVideo);
			}
			setFlightLabel('Payload Flight Test');
			setTestResults([]);
			setFileData(undefined);
		} else if (props.hwid.partNumber && livePayloadPartNumbers.includes(props.hwid.partNumber)) {
			setPreFlightTestData(livePayloadPreFlightTestData);
			let updatedPayloadInFlightTestData = livePayloadInFlightTestData;
			let payloadPartType = assemblyService.getPartAssemblyTypeFromPartNumber(props.hwid.partNumber);
			if (payloadPartType === undefined) payloadPartType = 'INERT_FRAG_PAYLOAD_ASSEMBLY';
			updatedPayloadInFlightTestData.runPreflight = (
				<LiveRunPreflight key={'liveUpdatedPreflight'} targetPayloadType={payloadPartType} />
			);

			setInFlightTestData(updatedPayloadInFlightTestData);
			if (socketIoService.getVideoFeatureAvailable()) {
				let updatedPayloadFlightTestData = livePayloadFlightTestData;
				console.log(payloadPartType);
				updatedPayloadFlightTestData.runPreflight = (
					<LiveRunPreflight key={'liveUpdatedPreflight'} targetPayloadType={payloadPartType} />
				);

				setFlightTestData(updatedPayloadFlightTestData);
				setPostFlightTestData(livePayloadPostFlightTestData);
			} else {
				let updatedPayloadFlightTestData = livePayloadFlightTestDataNoVideo;
				updatedPayloadFlightTestData.runPreflight = (
					<LiveRunPreflight key={'liveUpdatedPreflight'} targetPayloadType={payloadPartType} />
				);

				setFlightTestData(updatedPayloadFlightTestData);
				setPostFlightTestData(livePayloadPostFlightTestDataNoVideo);
			}
			setFlightLabel('Live Payload Flight Test');
			setTestResults([]);
			setFileData(undefined);
		} else if (props.hwid.partNumber && fcuPartNumbers.includes(props.hwid.partNumber)) {
			setShowPostFlightSection(false);
			setFlightTestData(fcuFlightTestData);
			setPreFlightTestData(fcuPreFlightTestData);
			setInFlightTestData(fcuInFlightTestData);
			setPostFlightTestData([]);
			setFlightLabel('FCU Flight Test');
			setTestResults([]);
			setFileData(undefined);
		} else if (props.hwid.partNumber && vehiclePartNumbers.includes(props.hwid.partNumber)) {
			if (!socketIoService.getVideoFeatureAvailable()) {
				setFlightTestData(vehicleFlightTestDataNoVideo);
				setPostFlightTestData(vehiclePostFlightTestDataNoVideo);
			}
			setFlightLabel('Vehicle Flight Test');
			setTestResults([]);
			setFileData(undefined);
		}
	}, [props.hwid.partNumber]);

	useEffect(() => {
		setIsModified(!!testResults.length);

		if (!props.hwid.partNumber) return;

		let dataGeneratedResult = testResults.find((res) => res.testName === 'generateData');
		if (dataGeneratedResult) {
			if (dataGeneratedResult.data.acknowledged == undefined) {
				if (dataGeneratedResult.passed) {
					// Let's do a quick check of the resulting data.
					const isPayloadSerial =
						payloadPartNumbers.includes(props.hwid.partNumber) && dataGeneratedResult.data.payloadSerial;
					const isVehicleSerial =
						vehiclePartNumbers.includes(props.hwid.partNumber) && dataGeneratedResult.data.vehicleSerial;

					// check to see if a comparison is needed at all.
					if (isPayloadSerial || isVehicleSerial) {
						// extract the right serial to compare with.
						let detectedSerial = '';
						if (isVehicleSerial) detectedSerial = dataGeneratedResult.data.vehicleSerial;
						else if (isPayloadSerial) detectedSerial = dataGeneratedResult.data.payloadSerial;
						// if the serials don't match, show the warning popup message.
						if (props.hwid.serialNumber !== detectedSerial && detectedSerial !== '') {
							let popupId = popupController.open<WarningPopupProps>(WarningPopup, {
								title: 'Serial Mismatch',
								message: `File Attached does not have the same serial number as the unit under test. Received ${detectedSerial}, expected ${props.hwid.serialNumber}.`,
								confirmButtonText: 'Continue',
								onConfirm: () => {}
							});
							return () => {
								if (popupId) popupController.closeById(popupId);
							};
						}
					}
				}
			}
		}
	}, [testResults]);

	async function handleDisconnectAndSave() {
		try {
			if (!testResults.length) return;
			popupController.open<LoadingPopupProps>(LoadingPopup, {});
			let part = await ApiRequestV1.getPartByNumbers({
				partNumber: props.hwid.partNumber,
				serialNumber: props.hwid.serialNumber
			});
			if (!part) {
				rsToastify.error('Missing Part Information', 'Could not find part to save data to.');
				popupController.close(LoadingPopup);
				return;
			}

			let res = await ApiRequestV1.postResult({ data: JSON.stringify(testResults) });

			let artifacts;
			let artifactDetailsList: any = [];
			const socketIoService = serviceFactory.get('SocketioService');
			if (ObjectUtils.isArrayWithData(fileData)) {
				for (let i = 0; i < fileData.length; i++) {
					let url = await ApiRequestV1.getTestResultPresignedUrl({
						pathFileName: `test-results/flight-tests/${connectionGuid}/${fileData[i].fileName}`,
						mimeType: fileData[i].mimeType
					});

					let fileUploadResult = await socketIoService.uploadFlightLogFile({
						filePath: fileData[i].filePath,
						url: url,
						mimeType: fileData[i].mimeType
					});

					// This whole statement is to capture the error case if the file upload fails
					if (socketIoService.isResponseAnError(fileUploadResult) || !fileUploadResult.success) {
						rsToastify.error('Failed to upload file', 'File Upload Error');
						popupController.close(LoadingPopup);
						return;
					}

					artifactDetailsList = [
						...artifactDetailsList,
						{
							fileUrl: url.split(fileData[i].fileName)[0] + fileData[i].fileName,
							fileSize: fileData[i].fileSize
						}
					];
				}
			}
			if (artifactDetailsList?.length > 0) {
				artifacts = JSON.stringify({
					data: artifactDetailsList
				});
			}

			// get the number of test keys and results that don't contain generateData (which just means a file entry was added)
			const realTestCount = Object.keys(flightTestData).filter((test) => test !== 'generateData').length;
			const realResultCount = testResults.filter((test) => test.testName !== 'generateData').length;

			let associatedAssemblyControls = associatedAssembliesFormGroup.getControls();
			console.log(associatedAssemblyControls);
			let associatedAssemblies = [];
			for (let i = 0; i < associatedAssemblyControls.length; i++) {
				let control = associatedAssemblyControls[i];
				if (control.value) {
					let decodedHardware = assemblyService.decodeHardwareId(control.value as string);
					let partNumber = decodedHardware?.partNumber ?? '';
					let serialNumber = decodedHardware?.serialNumber ?? '';
					associatedAssemblies.push({ partNumber, serialNumber });
				}
			}
			console.log(associatedAssemblies);

			const associatedBatteriesData = associatedBatteries
				.map((battery) => {
					const data = assemblyService.decodeHardwareId(battery);
					if (!data) return false;
					return {
						partNumber: data.partNumber,
						serialNumber: data.serialNumber
					};
				})
				.filter((item): item is { partNumber: string; serialNumber: string } => Boolean(item));

			await ApiRequestV1.postTestResult({
				partId: part.id,
				connectionGuid,
				testName: 'Flight Test',
				status: testResults.some((res) => !res.passed) ? 'FAIL' : 'PASS',
				hasPassed: testResults.every((res) => res.passed),
				isComplete: realResultCount === realTestCount,
				resultId: res.id,
				artifacts,
				...(associatedAssemblies.length && { associatedAssembly: associatedAssemblies }),
				...(associatedBatteriesData.length && { associatedBatteries: associatedBatteriesData })
			});

			rsToastify.success('Test results saved successfully.', 'Test Results Saved');
			socketIoService.sendFlightLogCleanupRequest();
			setTestResults([]);
			setFileData(undefined);
			popupController.close(LoadingPopup);
			props.onDone();
		} catch (e) {
			rsToastify.error(WebUtils.getRsErrorMessage(e, 'Unknown'), 'Server Error');
			popupController.close(LoadingPopup);
		}
	}

	function renderRightHeaderNode() {
		return (
			<>
				<Button
					look={'containedPrimary'}
					onClick={() => {
						isModified ? handleDisconnectAndSave() : props.onDone();
					}}
				>
					{isModified ? 'Disconnect & Save' : 'Disconnect'}
				</Button>
			</>
		);
	}

	function renderCenterNode() {
		if (!isModified) return <></>;
		let completedCount = testResults.filter((test) => test.testName !== 'generateData').length;
		return (
			<Label variant={'subheader2'} weight={'regular'}>
				{`${completedCount}/${Object.keys(flightTestData).length} completed`}
			</Label>
		);
	}

	function renderTests(testName: TestKey) {
		let testResult = testResults.find((test) => test.testName === testName);
		let status: TestStatus | undefined;
		if (testResult) {
			status = testResult.passed ? 'SUCCESS' : 'ERROR';
		}

		if (['loadVideo', 'loadFlightLog'].includes(testName)) {
			return (
				<Box display={'flex'} justifyContent={'space-between'} mb={16} id={testName} key={testName}>
					<Box>{flightTestData[testName]}</Box>
					<Box alignItems={'center'} height={22}>
						<StatusChip status={status ?? 'PENDING'} />
					</Box>
				</Box>
			);
		} else {
			return (
				<Box id={testName} key={testName}>
					<Box display={'flex'} justifyContent={'space-between'} mb={16}>
						<Label variant={'h3'} weight={'semiBold'}>
							{StringUtils.convertCamelCaseToHuman(testName)}
						</Label>
						<StatusChip status={status ?? 'PENDING'} />
					</Box>
					{flightTestData[testName]}
				</Box>
			);
		}
	}

	function scrollToTest(testId: TestKey) {
		const testElement = document.getElementById(testId);
		if (testElement) {
			testElement.scrollIntoView({
				behavior: 'smooth',
				block: 'start'
			});
		}
	}

	function renderTestList() {
		let elements: JSX.Element[] = [];
		Object.keys(flightTestData).forEach((testName) => {
			let testResult = testResults.find((test) => test.testName === testName);
			let status: TestStatus | undefined;
			if (testResult) {
				status = testResult.passed ? 'SUCCESS' : 'ERROR';
			}

			elements.push(
				<FlightTestItem
					key={testName}
					testName={StringUtils.convertCamelCaseToHuman(testName)}
					isSelected={testName === selectedTest}
					onClick={() => {
						setSelectedTest(testName as TestKey);
						scrollToTest(testName as TestKey);
					}}
					status={status}
				/>
			);
		});

		return elements;
	}

	useEffect(() => {
		if (
			associatedAssembliesFormGroup.get(FormKeys.ASSOCIATED_ASSEMBLY_1).value ||
			associatedAssembliesFormGroup.get(FormKeys.ASSOCIATED_ASSEMBLY_2).value ||
			!!associatedBatteries.length
		)
			setIsModified(true);
		else setIsModified(false);
	}, [associatedAssembliesFormGroup, associatedBatteries]);

	function handleUpdateControl(control: RsFormControl<IRsFormControl>) {
		setAssociatedAssembliesFormGroup(associatedAssembliesFormGroup.clone().update(control));
	}

	async function handlePartLookup(control: RsFormControl<IRsFormControl>) {
		if (!control.value) {
			handleUpdateControl(control);
			return;
		}

		const assemblyData = assemblyService.decodeHardwareId(control.value as string);

		if (!assemblyData) {
			rsToastify.warning('Invalid Assembly Data', 'Invalid Assembly Data');
			return;
		}

		const { partNumber, serialNumber } = assemblyData;

		try {
			await ApiRequestV1.getPartByNumbers({
				partNumber,
				serialNumber
			});
			handleUpdateControl(control);
		} catch (e) {
			console.error(e);
			rsToastify.error('Part not found.', 'Invalid Part Number');
		}
	}

	function renderBatteryInputs() {
		let batteries = associatedBatteries
			.map((battery, index) => {
				if (!battery) return false;
				return (
					<LabelInputText
						key={index}
						labelTitle={`Battery Used For Testing`}
						value={battery}
						inputMode="text"
						readOnly
						icon={[
							{
								iconImg: 'icon-delete',
								onClick: () => {
									let newBatteries = [...associatedBatteries.filter(Boolean)];
									newBatteries.splice(index, 1);
									setAssociatedBatteries(newBatteries);
								},
								position: 'RIGHT',
								cursorPointer: true
							}
						]}
					/>
				);
			})
			.filter(Boolean);

		batteries.push(
			<LabelInputText
				key={batteries.length + 1}
				labelTitle={`Battery Used For Testing ${batteries.length + 1}`}
				inputMode="text"
				onBlur={async ({ target }) => {
					if (!target.value) return;

					const assemblyData = assemblyService.decodeHardwareId(target.value as string);

					if (!assemblyData) {
						rsToastify.warning('Invalid Assembly Data', 'Invalid Assembly Data');
						return;
					}

					const { partNumber, serialNumber } = assemblyData;

					try {
						let test = await ApiRequestV1.getPartByNumbers({
							partNumber,
							serialNumber
						});
						if (!autoCreateableAssemblyPartNumbers.includes(test.partNumber)) {
							rsToastify.error('Part number is not a battery type.', 'Invalid Part Number');
							target.value = '';
							return;
						}
						let newBatteries = [...associatedBatteries.filter(Boolean), target.value];
						setAssociatedBatteries(newBatteries);
					} catch (e) {
						console.error(e);
						rsToastify.error('Part not found.', 'Invalid Part Number');
					}
				}}
			/>
		);

		return batteries;
	}

	function getAssociatedAssemblyLabels() {
		const assemblyType = assemblyService.getLabelFromPartNumber(props.hwid.partNumber);
		if (!assemblyType) return 'Associated Assembly';
		if (payloadPartNumbers.includes(props.hwid.partNumber))
			return ['Vehicle Used For Testing', 'FCU Used For Testing'];
		if (vehiclePartNumbers.includes(props.hwid.partNumber))
			return ['Payload Used For Testing', 'FCU Used For Testing'];
		if (fcuPartNumbers.includes(props.hwid.partNumber))
			return ['Vehicle Used For Testing', 'Payload Used For Testing'];
		else return ['Assembly Used For Testing', 'Assembly Used For Testing'];
	}

	return (
		<>
			<PageHeader
				title={flightLabel}
				isModified={isModified}
				centerNode={renderCenterNode()}
				rightNode={renderRightHeaderNode()}
			/>
			<Box className={'rsFlightTestSection'}>
				<Box className={'testList'}>{renderTestList()}</Box>
				<Box className={'main'}>
					<SelectableInputText
						labelTitle={`${assemblyService.getLabelFromPartNumber(props.hwid.partNumber)} Under Test`}
						control={
							new RsFormControl<string>(
								'main',
								`PN1:${props.hwid.partNumber},REV1:${props.hwid.hardwareRevision},SN1:${props.hwid.serialNumber}`
							)
						}
						isSelected={false}
						isFixed
						onClick={() => {}}
						updateControl={() => {}}
						onBlurOrEnter={async (value, enterPressed) => {
							return 'VALID';
						}}
					/>
					<LabelInputText
						labelTitle={getAssociatedAssemblyLabels()[0]}
						inputMode="text"
						control={associatedAssembliesFormGroup.get(FormKeys.ASSOCIATED_ASSEMBLY_1)}
						updateControl={debounce(handlePartLookup, 500)}
					/>
					<LabelInputText
						labelTitle={getAssociatedAssemblyLabels()[1]}
						inputMode="text"
						control={associatedAssembliesFormGroup.get(FormKeys.ASSOCIATED_ASSEMBLY_2)}
						updateControl={debounce(handlePartLookup, 500)}
					/>
					{renderBatteryInputs()}
					<Box className={'dividerLine'} />
					<Label variant={'h1'} weight={'bold'}>
						Pre-Flight Operations
					</Label>
					<Box className={'dividerLine'} />
					{Object.keys(preFlightTestData).map((test) => renderTests(test as TestKey))}
					<Label variant={'h1'} weight={'bold'}>
						In-Flight Operations
					</Label>
					<Box className={'dividerLine'} />
					{Object.keys(inFlightTestData).map((test) => renderTests(test as TestKey))}
					{showPostFlightSection && (
						<>
							<Label variant={'h1'} weight={'bold'}>
								Post-Flight Operations
							</Label>
							<Box className={'dividerLine'} />
							{Object.keys(postFlightTestData).map((test) => renderTests(test as TestKey))}
						</>
					)}
				</Box>
			</Box>
		</>
	);
};
export default FlightTestSection;
