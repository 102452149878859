import { Page } from '@redskytech/framework/996';
import {
	Box,
	Button,
	Checkbox,
	Icon,
	Label,
	LabelInputText,
	LabelSelect,
	Paper,
	RadioButtonGroup,
	RsFormControl,
	RsFormGroup,
	Select
} from '@redskytech/framework/ui';
import { IRsFormControl } from '@redskytech/framework/ui/form/FormControl';
import { format } from 'date-fns';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { DefinedRangeProps } from 'react-date-range';
import DateRangePicker from '../../components/dateRangePicker/DateRangePicker';
import PageHeader from '../../components/pageHeader/PageHeader';
import { partAssemblies } from '../../services/assembly/assembly.data';
import serviceFactory from '../../services/serviceFactory';
import { Fixture, TestFixtureType } from '../../services/testFixture/ITestFixtureService';
import { testFixtureTestMap } from '../../services/testFixture/testFixture.data';
import { fcuTestKeys } from '../flightTestPage/sections/FcuFlightTestList';
import { livePayloadTestKeys } from '../flightTestPage/sections/LivePayloadFlightTestList';
import { trainerPayloadTestKeys } from '../flightTestPage/sections/TrainerPayloadFlightTestList';
import { vehicleTestKeys } from '../flightTestPage/sections/VehicleFlightTestList';
import { magTestKeys } from '../magTestPage/MagTestSection';
import './ReportPage.scss';

enum FormKeys {
	REPORT_NAME = 'reportName',
	REPORT_TYPE = 'reportType',
	ASSEMBLY_TYPE = 'assemblyType',
	TEST_TYPE = 'testType',
	SERIAL_NUMBER_FILTER = 'serialNumberFilter',
	TEST_EXPORT_OPTION = 'testExportOption'
}

interface FormInterface {
	reportName: string;
	reportType: 'testValueExport' | 'firstPassYield';
	assemblyType: keyof typeof partAssemblies & '';
	testType?: keyof typeof testFixtureTestMap | 'Flight Test' | 'Magnetometer Calibration';
	serialNumberFilter: string;
	testExportOption: 'allTest' | 'passFail';
	subTest: string[];
}

function ReportPage(): JSX.Element {
	const reportService = serviceFactory.get('ReportService');
	const calendarWrapperRef = useRef<HTMLDivElement | null>(null);
	const [formGroup, setFormGroup] = useState<RsFormGroup>(
		new RsFormGroup([
			new RsFormControl<string>(
				FormKeys.REPORT_NAME,
				`${new Date().toDateString().replace(/ /g, '-')}-Report`,
				[]
			),
			new RsFormControl<string>(FormKeys.REPORT_TYPE, 'testValueExport', []),
			new RsFormControl<string | string[]>(FormKeys.ASSEMBLY_TYPE, '', []),
			new RsFormControl<string>(FormKeys.TEST_TYPE, '', []),
			new RsFormControl<string>(FormKeys.SERIAL_NUMBER_FILTER, '', []),
			new RsFormControl<string>(FormKeys.TEST_EXPORT_OPTION, 'allTest', [])
		])
	);

	const [selectedSubTest, setSelectedSubTest] = useState<string[]>([]);
	const [calendarOpen, setCalendarOpen] = useState(false);
	const [isComparePayloads, setIsComparePayloads] = useState(false);

	const [selectionRange, setSelectionRange] = useState<DefinedRangeProps['ranges']>([
		{
			startDate: undefined,
			endDate: undefined,
			key: 'selection'
		}
	]);

	const assemblyTypeOptions = useMemo(() => {
		return Object.keys(partAssemblies).map((key) => {
			let item = key as keyof typeof partAssemblies;
			return { label: partAssemblies[item].label, value: item };
		});
	}, []);

	const assemblyTypePayloadOptions = useMemo(() => {
		return Object.keys(partAssemblies)
			.filter((item) => (item as keyof typeof partAssemblies).toLowerCase().includes('payload'))
			.map((key) => {
				let item = key as keyof typeof partAssemblies;
				return { label: partAssemblies[item].label, value: item };
			});
	}, []);

	const testTypeOptions = useMemo(() => {
		let formData = formGroup.toModel<FormInterface>();

		if (isComparePayloads) {
			if (Array.isArray(formData.assemblyType)) {
				let newOptions: { label: string; value: string }[] = [];
				(formData.assemblyType as Array<keyof typeof partAssemblies>).forEach((assemblyType, index) => {
					let test = testFixtureTestMap[assemblyType];
					if (!test) return;
					newOptions = [
						...newOptions,
						...Object.keys(test)
							.map((key) => {
								let item = key as keyof typeof test;
								if (newOptions.some((option) => option.value === item)) return false;
								return { label: test[item]!.name, value: item };
							})
							.filter((item): item is { label: string; value: TestFixtureType } => Boolean(item))
					];
				});
				if (newOptions.length) return [...newOptions, { label: 'Flight Test', value: 'Flight Test' }];
				else return newOptions;
			}
		}

		let test = testFixtureTestMap[formData.assemblyType as keyof typeof partAssemblies];

		if (formData.assemblyType === '' || !test) {
			let newTestControl = formGroup.get(FormKeys.TEST_TYPE);
			newTestControl.value = '';
			setFormGroup(formGroup.clone().update(newTestControl));
			return [];
		}
		let options: { label: string; value: string }[] = Object.keys(test).map((key) => {
			let item = key as keyof typeof test;
			return { label: test[item]!.name, value: item };
		});

		if (formData.assemblyType === 'MILITARY_AIR_VEHICLE_ASSEMBLY') {
			options = [
				...options,
				{ label: 'Flight Test', value: 'Flight Test' },
				{ label: 'Magnetometer Calibration', value: 'Magnetometer Calibration' }
			];
		}

		if (
			(formData.assemblyType as string).toLowerCase().includes('fcu') ||
			(formData.assemblyType as string).toLowerCase().includes('payload')
		) {
			options = [...options, { label: 'Flight Test', value: 'Flight Test' }];
		}
		return options;
	}, [formGroup.get(FormKeys.ASSEMBLY_TYPE).value]);

	const subTestOptions = useMemo(() => {
		setSelectedSubTest([]);
		const formData = formGroup.toModel<FormInterface>();
		if (!formData.testType) return;
		let subTest = {} as {
			name: string;
			scriptName: string;
			tests: { label: string; testName: string; pythonArgs: string[] }[];
		};
		if (!isComparePayloads) {
			subTest = testFixtureTestMap[formData.assemblyType][formData.testType];
		} else {
			//Loop through formData.assemblyType and find assemblyType that has the testType

			if (Array.isArray(formData.assemblyType)) {
				(formData.assemblyType as Array<keyof typeof partAssemblies>).forEach((assemblyType, index) => {
					//@ts-ignore
					if (!testFixtureTestMap[assemblyType][formData.testType]) return;
					//@ts-ignore
					subTest = testFixtureTestMap[assemblyType][formData.testType];
				});
			}
		}

		if (formData.testType === 'Flight Test') {
			if (isComparePayloads) {
				if (Array.isArray(formData.assemblyType)) {
					let testKeys = livePayloadTestKeys as string[];
					if (
						(formData.assemblyType as Array<keyof typeof partAssemblies>).some(
							(item) => item === 'TRAINER_PAYLOAD_ASSEMBLY'
						)
					) {
						testKeys = [...testKeys, ...(trainerPayloadTestKeys as string[])];
					}
					let payloadTest = [...testKeys, 'associatedParts'].map((key) => {
						return { label: key, testName: key, pythonArgs: [] };
					});
					if (!subTest || !subTest.tests) {
						subTest = { name: 'Flight Test', scriptName: 'flightTest', tests: [] };
						subTest.tests = [...subTest.tests, ...payloadTest];
					}
				}
			} else if (formData.assemblyType === 'MILITARY_AIR_VEHICLE_ASSEMBLY') {
				let flightTest = [...vehicleTestKeys, ...magTestKeys, 'associatedParts'].map((key) => {
					return { label: key, testName: key, pythonArgs: [] };
				});
				if (!subTest || !subTest.tests) {
					subTest = { name: 'Flight Test', scriptName: 'flightTest', tests: [] };
					subTest.tests = [...flightTest];
				}
			} else if ((formData.assemblyType as string).toLowerCase().includes('payload')) {
				let testKeys = livePayloadTestKeys as string[];
				if (formData.assemblyType === 'TRAINER_PAYLOAD_ASSEMBLY') {
					testKeys = trainerPayloadTestKeys as string[];
				}
				let payloadTest = [...testKeys, 'associatedParts'].map((key) => {
					return { label: key, testName: key, pythonArgs: [] };
				});
				if (!subTest || !subTest.tests) {
					subTest = { name: 'Flight Test', scriptName: 'flightTest', tests: [] };
					subTest.tests = [...subTest.tests, ...payloadTest];
				}
			} else if ((formData.assemblyType as string).toLowerCase().includes('fcu')) {
				let fcuTest = [...fcuTestKeys, 'associatedParts'].map((key) => {
					return { label: key, testName: key, pythonArgs: [] };
				});

				if (!subTest || !subTest.tests) {
					subTest = { name: 'Flight Test', scriptName: 'flightTest', tests: [] };
					subTest.tests = [...subTest.tests, ...fcuTest];
				}
			}
		}

		if (formData.testType === 'Magnetometer Calibration') {
			let magTest = [...magTestKeys].map((key) => {
				return { label: key, testName: key, pythonArgs: [] };
			});
			if (!subTest || !subTest.tests) {
				subTest = { name: 'Magnetometer Calibration', scriptName: 'magnetometerCalibration', tests: [] };
				subTest.tests = [...magTest];
			}
		}

		if (!subTest.tests.length) {
			return;
		}

		return subTest.tests.map((test) => {
			return { label: test.label, value: test.testName };
		});
	}, [formGroup.get(FormKeys.TEST_TYPE).value]);

	useEffect(() => {
		function handleClickOutside(event: any) {
			if (
				calendarWrapperRef &&
				calendarWrapperRef.current &&
				!calendarWrapperRef.current.contains(event.target) &&
				!event.target.className.includes('calendarWrapper')
			) {
				setCalendarOpen(false);
			}
		}
		document.addEventListener('mousedown', handleClickOutside);
		return () => {
			document.removeEventListener('mousedown', handleClickOutside);
		};
	}, []);

	useEffect(() => {
		let newTestControl = formGroup.get(FormKeys.TEST_TYPE);
		newTestControl.value = '';
		setFormGroup(formGroup.clone().update(newTestControl));
	}, [formGroup.get(FormKeys.ASSEMBLY_TYPE).value]);

	function getAssemblyLabel(assemblyType: string | string[]) {
		if (Array.isArray(assemblyType))
			return assemblyType.map((item) => partAssemblies[item as keyof typeof partAssemblies].label);
		else return partAssemblies[assemblyType as keyof typeof partAssemblies].label;
	}

	async function getCsvData() {
		const { reportName, assemblyType, testType, serialNumberFilter, testExportOption, reportType } =
			formGroup.toModel<FormInterface>();
		const { startDate, endDate } = selectionRange![0];

		let testTypeValue: FormInterface['testType'];
		if (testType === 'Flight Test') {
			testTypeValue = 'Flight Test';
		} else if (testType === 'Magnetometer Calibration') {
			testTypeValue = 'Magnetometer Calibration';
		} else if (testType) {
			testTypeValue = (testFixtureTestMap[assemblyType][testType] as Fixture).name as FormInterface['testType'];
		}

		const data = {
			assemblyType: getAssemblyLabel(assemblyType),
			testExportOption,
			reportType,
			...(startDate &&
				endDate && {
					dateRange: { startDate: format(startDate, 'yyyy-MM-dd'), endDate: format(endDate, 'yyyy-MM-dd') }
				}),
			...(selectedSubTest.length && { subTest: selectedSubTest }),
			...(!!testType && { testType: testTypeValue }),
			...(!!serialNumberFilter && { serialNumberFilter: getFilterSerialNumberRange(serialNumberFilter) })
		};

		try {
			await reportService.downloadCSV(data, reportName);
		} catch (error) {
			console.error(error);
		}
	}

	function handleUpdateControl(control: RsFormControl<IRsFormControl>) {
		setFormGroup(formGroup.clone().update(control));
	}

	function getFilterSerialNumberRange(input: string): string[] {
		if (!input) return [];
		// Remove all characters except for alphanumerics, '-', ',' and spaces
		const sanitizedInput = input.replace(/[^a-zA-Z0-9*,\- ]/g, '');

		const segments = sanitizedInput.split(',').map((segment) => segment.trim());
		let result: string[] = [];

		segments.forEach((segment) => {
			if (segment.includes('-')) {
				const [start, end] = segment.split('-');
				const prefix = start.match(/[a-zA-Z]*/)?.[0] ?? '';
				const startNumStr = start.replace(prefix, '');
				const endNumStr = end.replace(prefix, '');
				const startNum = parseInt(startNumStr, 10);
				const endNum = parseInt(endNumStr, 10);

				if (!isNaN(startNum) && !isNaN(endNum) && startNum <= endNum) {
					const length = 5 - prefix.length;
					for (let i = startNum; i <= endNum; i++) {
						const numStr = String(i).padStart(length, '0');
						result.push(`${prefix}${numStr}`);
					}
				}
			} else if (segment.includes('*')) {
				result.push(segment);
			} else {
				const prefix = segment.match(/[a-zA-Z]*/)?.[0] ?? '';
				const numberPart = segment.replace(prefix, '');
				const length = 5 - prefix.length;
				const paddedNumber = numberPart.padStart(length, '0');
				result.push(`${prefix}${paddedNumber}`);
			}
		});

		return result;
	}

	function renderSubTestOptions() {
		if (!subTestOptions) return;

		let checkboxOptions = subTestOptions.map((item, index) => {
			return (
				<Checkbox
					key={item.value}
					checked={selectedSubTest.includes(item.value)}
					labelText={item.label}
					labelPosition="RIGHT"
					look="containedPrimary"
					onClick={() => {
						if (selectedSubTest.includes(item.value)) {
							setSelectedSubTest(selectedSubTest.filter((test) => test !== item.value));
						} else {
							setSelectedSubTest([...selectedSubTest, item.value]);
						}
					}}
				/>
			);
		});

		return [
			<Checkbox
				key={'checkAll'}
				checked={selectedSubTest.length === subTestOptions.length}
				labelText={'Check All'}
				labelPosition="RIGHT"
				look="containedPrimary"
				onClick={(event) => {
					let isChecked = (event.target as HTMLInputElement).checked;
					setSelectedSubTest(isChecked ? subTestOptions.map((test) => test.value) : []);
				}}
			/>,
			checkboxOptions
		];
	}

	return (
		<Page className="rsReportPage">
			<PageHeader title={'Reports'} />
			<Box className="pageContentWrapper">
				<Box className={'col1'}>
					<LabelInputText
						inputMode="text"
						labelTitle="Report Name"
						placeholder=""
						control={formGroup.get(FormKeys.REPORT_NAME)}
						updateControl={handleUpdateControl}
						mb={24}
					/>
					<LabelSelect<{ label: string; value: string }>
						labelTitle={'Report'}
						options={[
							{ label: 'Test Value Export', value: 'testValueExport' }
							// { label: 'First Pass Yield', value: 'firstPassYield' } To be added at a later date
						]}
						control={formGroup.get(FormKeys.REPORT_TYPE)}
						updateControl={handleUpdateControl}
					/>
					<hr />
					<Box display="grid" gap={'24px'}>
						<Button
							look={'outlinedPrimary'}
							onClick={() => {
								setFormGroup(formGroup.cloneDeep().resetToInitialValue());
								setSelectionRange([
									{
										startDate: undefined,
										endDate: undefined,
										key: 'selection'
									}
								]);
							}}
						>
							RESET ALL FILTERS
						</Button>
						<Box visibility={'hidden'}>
							<Checkbox
								look="containedPrimary"
								labelText="Compare Payloads"
								onClick={() => {
									const newFormGroupControl = formGroup.get(FormKeys.ASSEMBLY_TYPE);
									newFormGroupControl.value = '';
									handleUpdateControl(newFormGroupControl);
									setIsComparePayloads(!isComparePayloads);
								}}
							/>
						</Box>
						<LabelSelect<{ label: string; value: string }, boolean>
							labelTitle={isComparePayloads ? 'Payload Assemblies' : 'Assembly Type'}
							options={isComparePayloads ? assemblyTypePayloadOptions : assemblyTypeOptions}
							control={formGroup.get(FormKeys.ASSEMBLY_TYPE)}
							updateControl={handleUpdateControl}
							isMulti={isComparePayloads}
						/>

						<Box>
							<LabelInputText
								inputMode="text"
								labelTitle="Serial Number Filter Range"
								placeholder="102-109, 112, B102-B112"
								control={formGroup.get(FormKeys.SERIAL_NUMBER_FILTER)}
								updateControl={handleUpdateControl}
								mb={4}
							/>
							{!!getFilterSerialNumberRange(formGroup.get<string>(FormKeys.SERIAL_NUMBER_FILTER).value)
								.length && (
								<Label variant="body2" weight="bold" className="serialNumberLabel">
									{getFilterSerialNumberRange(
										formGroup.get<string>(FormKeys.SERIAL_NUMBER_FILTER).value
									).join(', ')}
								</Label>
							)}
						</Box>
						<Box elementRef={calendarWrapperRef} className="calendarWrapper">
							<Box className="fakeInput" onClick={() => setCalendarOpen(true)}>
								{!selectionRange![0].startDate || !selectionRange![0].endDate ? (
									<Label variant="body1" weight="regular">
										All Time
									</Label>
								) : (
									<Label variant="body1" weight="regular">
										From{' '}
										{selectionRange![0].startDate
											? format(selectionRange![0].startDate, 'yyyy/MM/dd')
											: ''}{' '}
										- To{' '}
										{selectionRange![0].endDate
											? format(selectionRange![0].endDate, 'yyyy/MM/dd')
											: ''}
									</Label>
								)}

								<Icon iconImg={'icon-calendar'} color="#868e96" ml="auto" />
							</Box>

							{calendarOpen && (
								<DateRangePicker
									elementRef={calendarWrapperRef}
									editableDateInputs={true}
									moveRangeOnFirstSelection={false}
									ranges={selectionRange}
									onChange={(item) => setSelectionRange([item.selection])}
									onSave={() => setCalendarOpen(false)}
									onClear={() => {
										setSelectionRange([
											{
												startDate: undefined,
												endDate: undefined,
												key: 'selection'
											}
										]);
										setCalendarOpen(false);
									}}
									startDatePlaceholder="Start Date"
									endDatePlaceholder="End Date"
									maxDate={new Date()}
								/>
							)}
						</Box>

						{!!testTypeOptions.length && (
							<Paper className="testTypePaper">
								<Label variant="body1" weight="regular">
									Test
								</Label>
								<RadioButtonGroup
									groupName={'testTypes'}
									options={[
										{ label: 'All Test Data', value: 'allTest' },
										{ label: 'Pass/Fail Only', value: 'passFail' }
									]}
									labelStyles={{ position: 'RIGHT', weight: 'regular', variant: 'body1' }}
									control={formGroup.get(FormKeys.TEST_EXPORT_OPTION)}
									updateControl={handleUpdateControl}
								/>

								<Select<{ label: string; value: string }>
									options={testTypeOptions}
									control={formGroup.get(FormKeys.TEST_TYPE)}
									updateControl={handleUpdateControl}
								/>

								{renderSubTestOptions()}
							</Paper>
						)}
					</Box>
				</Box>
				<Box className={'col2'}>
					<Button
						ml={'auto'}
						look={'containedPrimary'}
						onClick={() => {
							getCsvData();
						}}
					>
						EXPORT CSV
					</Button>
				</Box>
			</Box>
		</Page>
	);
}

export default ReportPage;
