import * as React from 'react';
import { Box, Checkbox, RsFormControl, RsFormGroup, RsValidator, RsValidatorEnum } from '@redskytech/framework/ui';
import { TestKey } from '../FlightTestSection';
import ServiceFactory from '../../../../services/serviceFactory';
import { useRecoilValue } from 'recoil';
import { TestResult } from '../../../../services/testFixture/ITestFixtureService';
import globalState from '../../../../state/globalState';
import { useState } from 'react';
import { IRsFormControl } from '@redskytech/framework/ui/form/FormControl';

interface MinWattsProfileFlightProps {}

enum FormKeys {
	MIN_WATTS_FLIGHT_EO = 'minWattsProfileFlightPerformedWithEo',
	MIN_WATTS_FLIGHT_IR = 'minWattsProfileFlightPerformedWithIr'
}

const MinWattsProfileFlight: React.FC<MinWattsProfileFlightProps> = () => {
	const TEST_NAME: TestKey = 'minWattsProfileFlight';
	const testFixtureService = ServiceFactory.get('TestFixtureService');
	const testResults = useRecoilValue<TestResult[]>(globalState.testResults);
	let res = testResults.find((prevRes) => prevRes.testName === TEST_NAME);
	const [formGroup, setFormGroup] = useState<RsFormGroup>(
		new RsFormGroup([
			new RsFormControl<boolean>(
				FormKeys.MIN_WATTS_FLIGHT_EO,
				res ? res.data[FormKeys.MIN_WATTS_FLIGHT_EO] : false,
				[
					new RsValidator(RsValidatorEnum.CUSTOM, '', (control) => {
						return !!control.value;
					})
				]
			),
			new RsFormControl<boolean>(
				FormKeys.MIN_WATTS_FLIGHT_IR,
				res ? res.data[FormKeys.MIN_WATTS_FLIGHT_IR] : false,
				[
					new RsValidator(RsValidatorEnum.CUSTOM, '', (control) => {
						return !!control.value;
					})
				]
			)
		])
	);

	function handleUpdateControl(control: RsFormControl<IRsFormControl>) {
		setFormGroup(formGroup.clone().update(control));
		testFixtureService.validateFlightTest(formGroup, TEST_NAME);
	}

	return (
		<Box className={'rsMinWattsProfileFlight'} flexDirection={'column'} gap={16}>
			<Checkbox
				labelText={'Performed min. watts flight using IR video feed'}
				look={'containedPrimary'}
				control={formGroup.get(FormKeys.MIN_WATTS_FLIGHT_IR)}
				updateControl={handleUpdateControl}
			/>
			<Checkbox
				labelText={'Performed min. watts flight using EO video feed'}
				look={'containedPrimary'}
				control={formGroup.get(FormKeys.MIN_WATTS_FLIGHT_EO)}
				updateControl={handleUpdateControl}
			/>
		</Box>
	);
};
export default MinWattsProfileFlight;
